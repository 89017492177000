<template>
  <v-runtime-template :template="template"></v-runtime-template>
</template>

<script>
import { RecommendationItem } from '@mezereon/ui-components-vue'

export default {
  mixins: [RecommendationItem],
  methods: {
    trackClick() {
      if (window.mz && window.mz.track) {
        window.mz.track('recommendation-click', {
          queryId: this.queryId,
          source: this.source,
          uid: this.item[this.primaryKey] + '',
          pos: this.index + 1
        })
      }
    }
  }
}
</script>
