<template>
  <div>
    <mz-bundle-recommendations
      v-if="display === 'bundle'"
      v-bind="$attrs"
    ></mz-bundle-recommendations>
    <base-recommendations v-else v-bind="$attrs" ref="recs">
      <template
        #recommendation-item="{
          template,
          html,
          item,
          queryId,
          source,
          layout,
          index,
          primaryKey,
          recommendationClick
        }"
      >
        <recommendation-html-item
          v-if="html"
          :template="template"
          :html="html"
          :item="item"
          :query-id="queryId"
          :source="source"
          :layout="layout"
          :index="index"
          :primary-key="primaryKey"
          @recommendation-click="recommendationClick"
        ></recommendation-html-item>
        <recommendation-item
          v-else
          :template="template"
          :item="item"
          :query-id="queryId"
          :source="source"
          :layout="layout"
          :index="index"
          :primary-key="primaryKey"
          @recommendation-click="recommendationClick"
        ></recommendation-item>
      </template>
    </base-recommendations>
  </div>
</template>

<script>
import { BaseRecommendations } from '@mezereon/ui-components-vue'
import MzBundleRecommendations from './MzBundleRecommendations.vue'
import RecommendationItem from './RecommendationItem.vue'
import RecommendationHtmlItem from './RecommendationHtmlItem.vue'

export default {
  name: 'MzBigCommerceRecommendations',
  components: {
    MzBundleRecommendations,
    BaseRecommendations,
    RecommendationItem,
    RecommendationHtmlItem
  },
  props: {
    display: {
      type: String,
      required: true
    }
  },
  methods: {
    refresh() {
      if (this.$refs.recs) {
        this.$refs.recs.refresh()
      }
    }
  },
  inheritAttrs: false
}
</script>
