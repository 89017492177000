var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.display === 'bundle')?_c('mz-bundle-recommendations',_vm._b({},'mz-bundle-recommendations',_vm.$attrs,false)):_c('base-recommendations',_vm._b({ref:"recs",scopedSlots:_vm._u([{key:"recommendation-item",fn:function({
        template,
        html,
        item,
        queryId,
        source,
        layout,
        index,
        primaryKey,
        recommendationClick
      }){return [(html)?_c('recommendation-html-item',{attrs:{"template":template,"html":html,"item":item,"query-id":queryId,"source":source,"layout":layout,"index":index,"primary-key":primaryKey},on:{"recommendation-click":recommendationClick}}):_c('recommendation-item',{attrs:{"template":template,"item":item,"query-id":queryId,"source":source,"layout":layout,"index":index,"primary-key":primaryKey},on:{"recommendation-click":recommendationClick}})]}}])},'base-recommendations',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }