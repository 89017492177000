<template>
  <div :class="cssClass">
    <div v-if="title" class="mz-title" v-html="title"></div>
    <div class="mz-bundle-top">
      <template v-for="(slide, idx) of filteredSlides">
        <slot
          name="recommendation-item"
          :html="slide.html"
          :template="template"
          :item="slide.item"
          :query-id="queryId"
          :source="slide.source"
          :layout="layout"
          :index="idx"
          :primary-key="primaryKey"
          :recommendation-click="recommendationClick"
        >
          <a class="mz-bundle-link" :href="slide.item.custom_url.url">
            <div class="mz-bundle-image-wrap">
              <img
                class="mz-bundle-image"
                :alt="slide.item.name"
                :title="slide.item.name"
                :src="slide.item.image"
              />
            </div>
          </a>
          <div class="mz-plus-sign" v-if="idx !== filteredSlides.length - 1">
            +
          </div>
        </slot>
      </template>
      <div class="mz-bundle-total">
        <div class="mz-bundle-total-container">
          <span class="mz-bundle-total-text">Total Price:</span>
          <div class="mz-bundle-total-wrap">
            <span class="mz-bundle-original-price-total">
              {{ totalOriginalPrice | currency }}
            </span>
            <span class="mz-bundle-price-total">
              {{ totalPrice | currency }}
            </span>
          </div>
        </div>
        <button class="mz-bundle-add-to-cart" @click="add2Cart">
          Add Selected to Cart
        </button>
      </div>
    </div>
    <div class="mz-bundle-list">
      <template v-for="(slide, idx) of slides">
        <label
          :class="[
            'mz-bundle-list-label',
            filteredSlides.length === 1 ? 'mz-locked' : '',
            slide.item.checked ? 'mz-checked' : 'mz-unchecked'
          ]"
          :for="zoneId + '-' + idx"
          :key="idx"
        >
          <input
            :id="zoneId + '-' + idx"
            type="checkbox"
            class="mz-bundle-list-checkbox"
            v-model="slide.item.checked"
          />
          <span v-if="idx === 0" class="mz-bundle-this-item">This item:</span>
          <span class="mz-bundle-list-title" v-html="slide.item.name"></span>
          <template
            v-if="
              itemVariants(slide.item) && itemVariants(slide.item).length > 1
            "
          >
            <select
              class="mz-bundle-variant-select"
              v-model="slide.item.selected_variant_id"
              :disabled="!slide.item.checked"
            >
              <option
                v-for="(variant, index) in itemVariants(slide.item)"
                :key="index"
                :value="variant.id"
              >
                {{ getVariantName(variant) }}
              </option>
            </select>
            <span class="mz-bundle-variant-price">
              {{ getVariantPrice(slide.item) | currency }}
            </span>
          </template>
          <span v-else class="mz-bundle-price-wrap">
            <span
              v-if="slide.item.calculated_price !== slide.item.price"
              class="mz-bundle-orig-price"
            >
              {{ slide.item.price | currency }}
            </span>
            <span class="mz-bundle-price">
              {{ slide.item.calculated_price | currency }}
            </span>
          </span>
        </label>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MzBundleRecommendations',
  props: {
    previewMode: {
      type: Boolean,
      required: false
    },
    primaryKey: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    slides: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    queryId: {
      type: String,
      default: ''
    },
    zoneId: {
      type: String,
      default: ''
    },
    cssClass: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      required: true,
      validator: (value) => {
        return ['horizontal', 'vertical'].includes(value)
      }
    },
    template: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalOriginalPrice: 0,
      totalPrice: 0
    }
  },
  methods: {
    itemVariants(item) {
      return item.variants.filter((variant) => !variant.purchasing_disabled)
    },
    add2Cart() {
      let items = this.filteredSlides.map((slide) => {
        let variant = this.itemVariants(slide.item).find(
          (variant) => variant.id === slide.item.selected_variant_id
        )
        return {
          quantity: 1,
          productId: slide.item.id,
          variantId: variant.id
        }
      })
      if (this.$bus) this.$bus.emit('bundle-add-to-cart', items)
    },
    refreshTotals() {
      let total = 0
      let originalTotal = 0
      this.filteredSlides.forEach((slide) => {
        if (
          this.itemVariants(slide.item) &&
          this.itemVariants(slide.item).length > 1
        ) {
          let variant = this.itemVariants(slide.item).find(
            (variant) => variant.id === slide.item.selected_variant_id
          )
          total += variant.calculated_price
          originalTotal += variant.calculated_price
          return
        }
        originalTotal += slide.item.price
        total += slide.item.calculated_price
      })
      this.totalOriginalPrice = originalTotal
      this.totalPrice = total
    },
    recommendationClick(e) {
      this.$emit('recommendation-click', e)
    },
    getVariantName(variant) {
      let name = ''
      let conn = ''
      let self = this
      Object.keys(variant).map((key) => {
        if (key.startsWith('option_')) {
          let value = Array.isArray(variant[key])
            ? variant[key][0]
            : variant[key]
          name += conn + self.$options.filters.value(value)
          conn = ' / '
        }
      })
      return name
    },
    getVariantPrice(item) {
      let variant = this.itemVariants(item).find(
        (variant) => variant.id === item.selected_variant_id
      )
      if (variant) {
        return variant.calculated_price
      }
    }
  },
  watch: {
    slides: {
      handler() {
        this.refreshTotals()
      },
      deep: true
    }
  },
  computed: {
    filteredSlides() {
      return this.slides.filter((slide) => {
        return slide.item.checked
      })
    }
  },
  created() {
    this.slides.forEach((slide) => {
      this.$set(slide.item, 'checked', true)
      this.$set(
        slide.item,
        'selected_variant_id',
        this.itemVariants(slide.item)[0].id
      )
    })
  },
  beforeDestroy() {}
}
</script>

<style>
.mz-title {
  text-align: center;
  font-size: 1.3em;
  height: 30px;
}
.mz-bundle-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.mz-recommendation-item {
  display: flex;
  justify-content: center;
}
.mz-bundle-image-wrap {
  position: relative;
  width: 120px;
}
.mz-bundle-image {
  width: 100%;
  height: 100%;
}
.mz-plus-sign {
  margin: 0 10px;
}
.mz-bundle-total {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.mz-bundle-total-text {
  font-weight: 700;
}
.mz-bundle-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.mz-bundle-list label:hover {
  cursor: pointer;
}
.mz-bundle-list-label {
  display: inline;
  margin: 5px 0;
}
.mz-checked {
  color: #000;
  font-weight: 700;
}
.mz-unchecked {
  color: #999;
  font-weight: 400;
  text-decoration: line-through;
}
.mz-bundle-list input,
.mz-bundle-list select {
  vertical-align: middle;
}
.mz-bundle-list-title,
.mz-bundle-variant-select,
.mz-bundle-variant-price,
.mz-bundle-price-wrap {
  margin-left: 5px;
  vertical-align: middle;
}
.mz-bundle-total-wrap {
  font-weight: 700;
  margin: 5px 0;
}
.mz-bundle-original-price-total,
.mz-bundle-orig-price {
  text-decoration: line-through;
  color: #999;
}
.mz-bundle-price-total,
.mz-bundle-price,
.mz-bundle-variant-price {
  color: #85bb65;
}
.mz-locked.mz-checked {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}
@media (max-width: 768px) {
  .mz-bundle-image-wrap {
    width: 100px;
  }
}
</style>
