var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.cssClass},[(_vm.title)?_c('div',{staticClass:"mz-title",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('div',{staticClass:"mz-bundle-top"},[_vm._l((_vm.filteredSlides),function(slide,idx){return [_vm._t("recommendation-item",function(){return [_c('a',{staticClass:"mz-bundle-link",attrs:{"href":slide.item.custom_url.url}},[_c('div',{staticClass:"mz-bundle-image-wrap"},[_c('img',{staticClass:"mz-bundle-image",attrs:{"alt":slide.item.name,"title":slide.item.name,"src":slide.item.image}})])]),(idx !== _vm.filteredSlides.length - 1)?_c('div',{staticClass:"mz-plus-sign"},[_vm._v(" + ")]):_vm._e()]},{"html":slide.html,"template":_vm.template,"item":slide.item,"queryId":_vm.queryId,"source":slide.source,"layout":_vm.layout,"index":idx,"primaryKey":_vm.primaryKey,"recommendationClick":_vm.recommendationClick})]}),_c('div',{staticClass:"mz-bundle-total"},[_c('div',{staticClass:"mz-bundle-total-container"},[_c('span',{staticClass:"mz-bundle-total-text"},[_vm._v("Total Price:")]),_c('div',{staticClass:"mz-bundle-total-wrap"},[_c('span',{staticClass:"mz-bundle-original-price-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalOriginalPrice))+" ")]),_c('span',{staticClass:"mz-bundle-price-total"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalPrice))+" ")])])]),_c('button',{staticClass:"mz-bundle-add-to-cart",on:{"click":_vm.add2Cart}},[_vm._v(" Add Selected to Cart ")])])],2),_c('div',{staticClass:"mz-bundle-list"},[_vm._l((_vm.slides),function(slide,idx){return [_c('label',{key:idx,class:[
          'mz-bundle-list-label',
          _vm.filteredSlides.length === 1 ? 'mz-locked' : '',
          slide.item.checked ? 'mz-checked' : 'mz-unchecked'
        ],attrs:{"for":_vm.zoneId + '-' + idx}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(slide.item.checked),expression:"slide.item.checked"}],staticClass:"mz-bundle-list-checkbox",attrs:{"id":_vm.zoneId + '-' + idx,"type":"checkbox"},domProps:{"checked":Array.isArray(slide.item.checked)?_vm._i(slide.item.checked,null)>-1:(slide.item.checked)},on:{"change":function($event){var $$a=slide.item.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(slide.item, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(slide.item, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(slide.item, "checked", $$c)}}}}),(idx === 0)?_c('span',{staticClass:"mz-bundle-this-item"},[_vm._v("This item:")]):_vm._e(),_c('span',{staticClass:"mz-bundle-list-title",domProps:{"innerHTML":_vm._s(slide.item.name)}}),(
            _vm.itemVariants(slide.item) && _vm.itemVariants(slide.item).length > 1
          )?[_c('select',{directives:[{name:"model",rawName:"v-model",value:(slide.item.selected_variant_id),expression:"slide.item.selected_variant_id"}],staticClass:"mz-bundle-variant-select",attrs:{"disabled":!slide.item.checked},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(slide.item, "selected_variant_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.itemVariants(slide.item)),function(variant,index){return _c('option',{key:index,domProps:{"value":variant.id}},[_vm._v(" "+_vm._s(_vm.getVariantName(variant))+" ")])}),0),_c('span',{staticClass:"mz-bundle-variant-price"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getVariantPrice(slide.item)))+" ")])]:_c('span',{staticClass:"mz-bundle-price-wrap"},[(slide.item.calculated_price !== slide.item.price)?_c('span',{staticClass:"mz-bundle-orig-price"},[_vm._v(" "+_vm._s(_vm._f("currency")(slide.item.price))+" ")]):_vm._e(),_c('span',{staticClass:"mz-bundle-price"},[_vm._v(" "+_vm._s(_vm._f("currency")(slide.item.calculated_price))+" ")])])],2)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }