<template>
  <div v-if="widgets">
    <template v-for="item in widgets">
      <mounting-portal
        v-if="item.slides.length >= item.minCount"
        :key="item.zoneId"
        :mount-to="getTargetSelector(item.zoneId)"
        append
      >
        <mz-big-commerce-recommendations
          ref="recs"
          primary-key="id"
          :display="item.display"
          :query-id="queryId"
          :zone-id="item.zoneId"
          :title="item.title"
          :css-class="item.layout.cssClass"
          :layout="item.layout.layout"
          :template="item.layout.template"
          :slides="item.slides"
          :settings="item.layout.settings"
          @recommendation-click="onClick"
        ></mz-big-commerce-recommendations>
      </mounting-portal>
    </template>
  </div>
</template>

<script>
/* global mz */
import MzBigCommerceRecommendations from './custom/MzBigCommerceRecommendations.vue'
import { mapState } from 'vuex'
import { setCustomCSS } from '@/helpers'

export default {
  components: { MzBigCommerceRecommendations },
  data() {
    return {
      widgets: null,
      queryId: null,
      segments: null
    }
  },
  computed: {
    ...mapState('search', ['context', 'filters'])
  },
  mounted() {
    this.loadRecommendations(false)
  },
  methods: {
    onClick(e) {
      if (mz && mz.track) {
        mz.track('recommendation-click', e)
      }
    },
    getTargetSelector(zoneId) {
      return `[data-mz-zone-id="${zoneId}"]`
    },
    parse(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        return str
      }
    },
    queryItems() {
      const self = this
      const itemList = document.querySelectorAll('[data-mz-item]')
      let items = []
      Array.from(itemList).forEach(function (item) {
        const value = self.parse(item.dataset.mzItem)
        if (Array.isArray(value)) {
          items = items.concat(value)
        } else {
          items.push(value)
        }
      })
      return items
    },
    queryFilter() {
      const filterList = document.querySelectorAll('[data-mz-filter]')
      const filters = {}
      Array.from(filterList).forEach(function (filter) {
        const key = filter.dataset.mzFilter
        filters[key] = filter.dataset.mzValue
      })
      return filters
    },
    queryAllZones() {
      const zoneList = document.querySelectorAll('[data-mz-zone-id]')
      const zones = []
      Array.from(zoneList).forEach(function (zone) {
        zones.push({
          id: zone.dataset.mzZoneId,
          display: zone.dataset.mzDisplay ?? 'carousel'
        })
      })
      return zones
    },
    loadRecommendations(refresh, included) {
      const self = this
      let zones = this.queryAllZones()
      // if zones passed, then filter zones
      if (included && Array.isArray(included)) {
        zones = zones.filter((x) => included.includes(x.id))
      }
      const zoneIds = zones.map((x) => x.id)
      const items = this.queryItems()
      const filter = this.queryFilter()
      const filters = this.filters
      const context = {
        visitId: mz ? mz.getVisitId() : '',
        visitorId: mz ? mz.getVisitorId() : '',
        tags: this.context.tags,
        items,
        filter
      }
      if (zones.length === 0) {
        return
      }
      this.$axios
        .post('/recommendation/query', { zones: zoneIds, context, filters })
        .then((res) => {
          // inject custom css
          Object.keys(res.data.widgets).forEach(function (key) {
            setCustomCSS(key, res.data.widgets[key].layout.customCss)
            let display = zones.find((zone) => zone.id == key).display
            res.data.widgets[key].display = display
          })
          const widgets = []
          const updated = self.widgets || res.data.widgets
          Object.keys(res.data.widgets).forEach(function (key) {
            updated[key] = res.data.widgets[key]
            widgets.push({
              zoneId: key,
              total: res.data.widgets[key].slides.length,
              uids: res.data.widgets[key].slides.map((x) => x.item.id),
              primary: res.data.widgets[key].slides.filter(
                (x) => x.source === 'primary'
              ).length
            })
          })

          self.widgets = updated
          self.queryId = res.data.queryId
          self.segments = res.data.segments

          if (mz && mz.track && Object.keys(self.widgets).length > 0) {
            mz.track('recommendation', {
              queryId: self.queryId,
              zones: zoneIds,
              segments: self.segments,
              context,
              widgets
            })
          }
          if (refresh) {
            self.$refs.recs.forEach((single) => {
              if (single.refresh) {
                self.$nextTick(() => {
                  single.refresh()
                })
              }
            })
          }

          // send bus event for each wiget
          Object.keys(self.widgets).forEach(function (key) {
            let widget = self.widgets[key]
            let event = {
              zoneId: widget.zoneId,
              slides: widget.slides
            }
            if (self.$bus) self.$bus.emit('widget-updated', event)
          })
        })
    }
  }
}
</script>

<style></style>
